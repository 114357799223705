// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RadarPageQuery_graphql from "../__generated__/RadarPageQuery_graphql.mjs";

function serialize(t) {
  return t.type + ":" + t.brand + ":" + t.order;
}

function parse(s) {
  var match = s.split(":");
  if (match.length !== 3) {
    return ;
  }
  var type_ = match[0];
  var brand = match[1];
  var order = match[2];
  var order$1 = RadarPageQuery_graphql.Utils.sortOrder_fromString(order);
  var type_$1 = RadarPageQuery_graphql.Utils.sortType_fromString(type_);
  var brand$1 = RadarPageQuery_graphql.Utils.sortBrands_fromString(brand);
  if (type_$1 !== undefined && brand$1 !== undefined && order$1 !== undefined) {
    return {
            brand: brand$1,
            order: order$1,
            type: type_$1
          };
  }
  
}

function brandToDisplayName(brand) {
  switch (brand) {
    case "billedbladet" :
        return "Billed-Bladet";
    case "dagbladet" :
        return "Dagbladet";
    case "hant" :
        return "Hänt";
    case "seoghoer" :
        return "Se og Hør";
    case "svenskdam" :
        return "Svensk Dam";
    
  }
}

function make(type_Opt, brandOpt, orderOpt, param) {
  var type_ = type_Opt !== undefined ? type_Opt : "publishedAt";
  var brand = brandOpt !== undefined ? brandOpt : "hant";
  var order = orderOpt !== undefined ? orderOpt : "DESCENDING";
  return {
          brand: brand,
          order: order,
          type: type_
        };
}

var $$default = {
  brand: "hant",
  order: "DESCENDING",
  type: "publishedAt"
};

export {
  serialize ,
  parse ,
  $$default as default,
  brandToDisplayName ,
  make ,
}
/* RadarPageQuery_graphql Not a pure module */
