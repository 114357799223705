// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TailwindMerge from "tailwind-merge";
import * as JsxRuntime from "react/jsx-runtime";

function ServerCrashIcon(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsxs("svg", {
              children: [
                JsxRuntime.jsx("path", {
                      d: "M6 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-2"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M6 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M6 6h.01"
                    }),
                JsxRuntime.jsx("path", {
                      d: "M6 18h.01"
                    }),
                JsxRuntime.jsx("path", {
                      d: "m13 6-4 6h6l-4 6"
                    })
              ],
              className: TailwindMerge.twMerge([
                    "size-5",
                    className
                  ]),
              height: "24",
              width: "24",
              fill: "none",
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "2",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = ServerCrashIcon;

export {
  make ,
}
/* tailwind-merge Not a pure module */
