// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorBoundary from "./ErrorBoundary.mjs";
import * as ServerCrashIcon from "../icons/ServerCrashIcon.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function RouteErrorBoundary(props) {
  return JsxRuntime.jsx(ErrorBoundary.make, {
              children: props.children,
              fallback: (function (param) {
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ServerCrashIcon.make, {
                                            className: "size-16 stroke-muted-foreground"
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("h1", {
                                                    children: "Page failed to load",
                                                    className: "text-2xl font-bold"
                                                  }),
                                              JsxRuntime.jsx("p", {
                                                    children: "Something went wrong."
                                                  })
                                            ]
                                          })
                                    ],
                                    className: "max-w-xl mx-auto text-muted-foreground bg-muted rounded-md border p-8 flex items-center gap-8"
                                  }),
                              className: "flex items-center grow h-full p-4"
                            });
                })
            });
}

var make = RouteErrorBoundary;

export {
  make ,
}
/* ErrorBoundary Not a pure module */
